import React from 'react'
import styled from 'styled-components'
import media from 'styles/media'

import { ImageType } from 'types/image'

import { Container } from 'components/atoms/Container'
import { H2Light } from 'components/atoms/Typography'
import SalesCard from 'components/modules/SalesCard/SalesCard'

const OtherSalesContainer = styled(Container)`
  max-width: calc(1136px + 100px);

  display: flex;
  flex-direction: column;
  gap: 40px;

  ${media.sm.max} {
    padding: 48px 24px;
  }
`

const TilesWraper = styled.div`
  display: grid;
  grid-template-rows: repeat(auto, 1fr);
  grid-template-columns: repeat(3, 1fr);
  row-gap: 40px;
  column-gap: 40px;

  ${media.xl.max} {
    grid-template-columns: repeat(2, 1fr);
  }
  ${media.md.max} {
    grid-template-columns: repeat(1, 1fr);
  }
`

const StyledH2Light = styled(H2Light)`
  ${media.sm.max} {
    max-width: 272px;
  }
`

type OtherSalesProps = {
  heading: string
  tiles: Array<{
    image: ImageType
    saleTitle: string
    saleRedirect: string
  }>
}

export const OtherSales = ({
  otherSalesContent,
}: {
  otherSalesContent: OtherSalesProps
}) => {
  const { heading, tiles } = otherSalesContent

  return (
    <OtherSalesContainer padding={[100, 50]} margin={[0, 'auto']}>
      <StyledH2Light as="h4" dangerouslySetInnerHTML={{ __html: heading }} />
      <TilesWraper>
        {tiles.map((item) => (
          <SalesCard tileData={item} />
        ))}
      </TilesWraper>
    </OtherSalesContainer>
  )
}
