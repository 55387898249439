import React from 'react'

import { ImageType } from 'types/image'

import { LazyImage } from 'components/atoms/Image'
import { H1Light, TextBody1 } from 'components/atoms/Typography'
import {
  AboutSaleContainer,
  ImageWraper,
  TextContainer,
} from './AboutSales.styles'

type AboutSaleProps = {
  heading: string
  content: string
  image: ImageType
}

export const AboutSale: React.FC<AboutSaleProps> = ({
  heading,
  content,
  image,
}) => {
  return (
    <AboutSaleContainer padding={[100, 50]} margin={[0, 'auto']}>
      <ImageWraper>
        <LazyImage src={image.src} alt={image.alt!} />
      </ImageWraper>
      <TextContainer>
        <H1Light dangerouslySetInnerHTML={{ __html: heading }} />
        <TextBody1 dangerouslySetInnerHTML={{ __html: content }} />
      </TextContainer>
    </AboutSaleContainer>
  )
}
