import React from 'react'
import styled from 'styled-components'
import media from 'styles/media'

import { Container } from 'components/atoms/Container'
import { FlatSearch } from 'components/organisms/SearchFlat/FlatSearch/FlatSearch'
import { H1Light } from 'components/atoms/Typography'
import useFlatContext from 'hooks/useFlatContext'

import { Flat } from 'types/domain'

const SearchWraper = styled(Container)`
  max-width: none;
  box-sizing: border-box;

  ${media.xl.max} {
    padding-left: 16px;
    padding-right: 16px;
  }
  ${media.sm.max} {
    padding-left: 0px;
    padding-right: 0px;
  }
`

const ContentWraper = styled(Container)`
  max-width: none;
  background-color: #f3f3f3;

  ${media.sm.max} {
    padding-left: 0px;
    padding-right: 0px;
  }
`

const Content = styled(Container)`
  max-width: 1136px;

  position: relative;
`

const StyledH1Light = styled(H1Light)`
  max-width: 536px;

  margin: 0px auto;
  margin-bottom: 48px;
  ${media.sm.max} {
    max-width: 320px;
    margin-top: 48px;
  }
`

export const FlatsOnSale = ({
  heading,
  promoFlats,
  showAllAvailable,
}: {
  heading: string
  promoFlats: Array<{
    flatId: string
    flatPrice?: number
  }>
  showAllAvailable?: boolean
}) => {
  const { flats } = useFlatContext()

  const availableFlats = showAllAvailable
    ? flats.filter((flat) => flat.status !== 'sold')
    : flats

  const flatsOnSale: Flat[] = availableFlats.reduce((acc, flat) => {
    /* eslint-disable array-callback-return */
    promoFlats?.map(({ flatId, flatPrice }) => {
      if (flatId === flat.urlID) {
        acc.push({ ...flat, flatPrice })
      }
    })
    return acc
  }, [] as Flat[])

  return (
    <>
      <StyledH1Light
        align="center"
        as="h2"
        dangerouslySetInnerHTML={{ __html: heading }}
      />

      <SearchWraper padding={[0, 32]}>
        <ContentWraper padding={[64, 32]}>
          <Content margin={[0, 'auto']}>
            <FlatSearch
              headingTag="h5"
              flats={showAllAvailable ? availableFlats : flatsOnSale}
            />
          </Content>
        </ContentWraper>
      </SearchWraper>
    </>
  )
}
