import styled from 'styled-components'
import media from 'styles/media'

import { Container } from 'components/atoms/Container'

export const SalesHeroContainer = styled(Container)<{
  extraPaddingBottom: number
}>`
  max-width: calc(1136px + 80px);

  padding-bottom: ${({ extraPaddingBottom }) =>
    extraPaddingBottom ? `calc(72px + ${extraPaddingBottom}px)` : '72px'};

  display: flex;

  ${media.lg.max} {
    flex-direction: column-reverse;
    justify-content: center;
    align-items: center;
  }
  ${media.sm.max} {
    padding: 0px;
    padding-bottom: ${({ extraPaddingBottom }) =>
      extraPaddingBottom ? `calc(48px + ${extraPaddingBottom}px)` : '48px'};
  }
`

export const GreenBox = styled.div<{ extraPaddingBottom: number }>`
  width: 100%;
  max-width: 50%;

  padding: 64px;
  padding-bottom: ${({ extraPaddingBottom }) =>
    extraPaddingBottom ? `calc(64px + ${extraPaddingBottom}px)` : '64px'};

  position: relative;

  background-color: ${({ theme }) => theme.colors.primary};

  ${media.lg.max} {
    max-width: 80%;
  }
  ${media.md.max} {
    max-width: 100%;
  }
  ${media.sm.max} {
    padding: 32px;
    padding-bottom: ${({ extraPaddingBottom }) =>
      extraPaddingBottom ? `calc(48px + ${extraPaddingBottom}px)` : '48px'};
  }
`

export const ImageWraper = styled.div`
  width: 100%;
  max-width: 50%;

  aspect-ratio: 568 / 480;

  & .gatsby-image-wrapper {
    width: 100%;
    height: 100%;
  }

  ${media.lg.max} {
    max-width: 80%;
  }
  ${media.md.max} {
    max-width: 100%;
  }

  ${media.sm.max} {
    aspect-ratio: 320 / 360;
  }
`

export const ContentContainer = styled.div`
  width: calc(100% - 128px);

  padding: 40px;

  position: absolute;
  bottom: 0px;
  left: 50%;

  display: flex;
  flex-direction: column;
  gap: 16px;

  transform: translate(-50%, 50%);

  background-color: white;
  box-shadow: 4px 4px 20px 0px rgba(0, 0, 0, 0.15);

  ${media.sm.max} {
    width: calc(100% - 48px);
    padding: 24px;
  }
`
