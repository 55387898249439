import React, { useRef, useState, useEffect } from 'react'

import { ImageType } from 'types/image'

import { LazyImage } from 'components/atoms/Image'
import { H2Light, H3Bold, TextBody1 } from 'components/atoms/Typography'
import {
  ContentContainer,
  GreenBox,
  ImageWraper,
  SalesHeroContainer,
} from './SalesHero.styles'

type SalesHeroProps = {
  heading: string
  contentHeading: string
  contentText: string
  image: ImageType
}

export const SalesHero: React.FC<SalesHeroProps> = ({
  heading,
  contentHeading,
  contentText,
  image,
}) => {
  const contentBoxRef = useRef<HTMLDivElement>(null)
  const [height, setHeight] = useState(0)

  useEffect(() => {
    const updateHeight = () => {
      if (contentBoxRef.current) {
        setHeight(contentBoxRef.current.clientHeight)
      }
    }

    updateHeight()

    window.addEventListener('resize', updateHeight)

    return () => {
      window.removeEventListener('resize', updateHeight)
    }
  }, [])

  const memoizedHeight = React.useMemo(() => height, [height])

  return (
    <SalesHeroContainer
      padding={[0, 40]}
      margin={[0, 'auto']}
      extraPaddingBottom={memoizedHeight / 2}
    >
      <GreenBox extraPaddingBottom={memoizedHeight / 2}>
        <H2Light
          as="h3"
          color="white"
          dangerouslySetInnerHTML={{ __html: heading }}
        />
        <ContentContainer ref={contentBoxRef}>
          <H3Bold dangerouslySetInnerHTML={{ __html: contentHeading }} />
          <TextBody1 dangerouslySetInnerHTML={{ __html: contentText }} />
        </ContentContainer>
      </GreenBox>
      <ImageWraper>
        <LazyImage src={image.src} alt={image.alt!} objectFit="cover" />
      </ImageWraper>
    </SalesHeroContainer>
  )
}
