import styled from 'styled-components'
import media from 'styles/media'

import { Container } from 'components/atoms/Container'

export const AboutSaleContainer = styled(Container)`
  max-width: calc(1136px + 100px);

  display: flex;
  gap: 64px;

  ${media.lg.max} {
    flex-direction: column-reverse;
    align-items: center;

    gap: 32px;
  }
  ${media.sm.max} {
    padding: 48px 24px;
  }
`

export const ImageWraper = styled.div`
  width: 100%;
  max-width: 536px;

  position: relative;
  aspect-ratio: 536/560;

  & .gatsby-image-wrapper {
    width: 100%;
    height: 100%;
  }

  &::before {
    content: '';
    width: 86px;
    height: 87px;

    position: absolute;
    bottom: -16px;
    left: -16px;

    border-radius: 12px;
    background: ${({ theme }) => theme.colors.primary};
  }

  ${media.sm.max} {
    max-width: 400px;

    aspect-ratio: 272/360;

    &::before {
      width: 38.393px;
      height: 38.839px;

      position: absolute;
      bottom: -8px;
      left: -8px;
      border-radius: 5.357px;
    }
  }
`

export const TextContainer = styled.div`
  width: 100%;
  max-width: 536px;

  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 12px;
`
